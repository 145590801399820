// import React, {
//   useRef,
//   useState,
//   useEffect,
//   useContext,
//   Suspense,
//   lazy,
// } from 'react';
// import ReactDOM from 'react-dom';
// import {
//   BrowserRouter as Router,
//   Routes,
//   Route,
//   Link as RouterLink,
//   NavLink,
//   useNavigate,
// } from 'react-router-dom';
// import { Box } from '@mui/material';
// import { useMediaQuery } from '@mui/material';
// import { I18nextProvider } from 'react-i18next';
// import i18n from './i18n';
// import KeywordMain from './KeywordMain';
// import requestPermission from './requestPermission';
// import ProductContext from './Context/productContext';
// import { GlobalProvider, useGlobalContext } from './GlobalContext';
// import { useDispatch, useSelector } from 'react-redux';
// import { setSearchKeyword } from './Redux/actions/searchAction';
// import { firebaseApp, auth, RecaptchaVerifier } from './firebaseConfig';
// import { createTheme, ThemeProvider } from '@mui/material/styles';
// import { useTheme, CssBaseline } from '@mui/material';
// import { db, doc, getDoc } from './firebaseConfig';
// import './css/global.css';

// import ResponsiveContainer from './ResponsiveContainer';
// import NavBar from './NavBar/NavBar';
// import MobileTabBar from './NavBar/MobileTabBar';
// import Home from './Home';
// import Detail from './Detail';
// import Upload from './Upload';
// import Login from './Login';
// import Join from './Join';
// import Chat from './Chat';
// import ChatList from './ChatList';
// import ChatView from './ChatView';
// import HeartList from './HeartList';
// import Edit from './Edit';
// import Settings from './Settings';
// import MyTown from './SignUp/MyTown';
// import ImageViewer from './ImageViewer';
// import KakaoRedirect from './KakaoRedirect';
// import NaverRedirect from './NaverRedirect';
// import NotFound from './NotFound';
// import UserSignUpFlow from './SignUp/UserSignUpFlow';
// import StoreSignUpFlow from './SignUp/StoreSignUpFlow';
// import PhoneNumberVerification from './SignUp/PhoneNumberVerification';
// import StoreDashboard from './Stores/StoreAdmin/StoreDashboard';
// import StoreList from './Stores/StoreList';
// import MyStore from './Stores/MyStore';
// import EditMyStore from './Stores/EditMyStore';
// import StoreHome from './Stores/StoreHome';
// import StoreProductUpload from './Stores/StoreProductUpload';
// import StoreProductList from './Stores/StoreProductList';
// import StoreProduct from './Stores/StoreProduct';
// import StoreProductDetail from './Stores/StoreProductDetail';
// import SignUpEditPassword from './SignUp/SignUpEditPassword';
// import EditPromotion from './Stores/Promotion/EditPromotion';
// import PromotionList from './Stores/Promotion/PromotionList';
// import PromotionWithProduct from './Stores/Promotion/PromotionWithProduct';
// import CartList from './Stores/Cart/CartList';
// import TermsAgreementScreen from './Stores/Admin/TermsAgreementScreen';
// import RegisterTerms from './Stores/Admin/RegisterTerms';
// import PrivacyList from './Stores/Admin/PrivacyList';
// import RegisterPrivacy from './Stores/Admin/RegisterPrivacy';
// import UserProfile from './UserProfile';
// import MyPage from './Stores/MyPage';
// import Orders from './Stores/Orders';
// import StoreOrders from './Stores/StoreOrders';
// import LeftSideMenu from './LeftSideMenu';
// import StoreChatView from './Stores/StoreChatView';
// import SuccessPage from './Stores/Payment/Toss/SuccessPage';
// import FailPage from './Stores/Payment/Toss/FailPage';
// import './App.css';
// import homeImage from './assets/images/home.png';
// import ProductReviewDetail from './Stores/ProductReviewDetail';
// import Returns from './Stores/StoreAdmin/Returns';
// import OrderStatus from './Stores/OrderStatus';
// import ProductKeywordSearchResults from './ProductKeywordSearchResults';
// import ProductCategorySearchResults from './ProductCategorySearchResults';
// import ProductBrandSearchResults from './ProductBrandSearchResults';
// import theme from './theme';
// import AllCartList from './Stores/Cart/AllCartList';
// import TermsList from './Stores/Admin/TermsList';
// import FloatingSidebar from './FloatingSidebar';
// import TermsCheck from './Stores/Admin/TermsCheck';
// import PrivacyCheck from './Stores/Admin/PrivacyCheck';
// import PrivacyAgreementScreen from './Stores/Admin/PrivacyAgreementScreen';
// import CategoryBrandSelector from './CategoryBrandSelector';

// import SellList from './SellList';
// import BuyList from './BuyList';
// import StoreDeliveryArea from './SignUp/StoreDeliveryArea';
// import StorePosition from './SignUp/StorePosition';
// import StoreProfile from './SignUp/StoreProfile';
// import UserAddress from './UserAddress';
// import TranslateComponent from './TranslateComponent';
// import MobileNavBar from './NavBar/MobileNavBar';
// import SignUpSetPassword from './SignUp/SignUpSetPassword';
// import CheckPasswordSetup from './CheckPasswordSetup';
// import DeleteUserAccount from './DeleteUserAccount';
// import DeleteStoreAccount from './DeleteStoreAccount';

// // import { useCollectionData } from 'react-firebase-hooks/firestore';
// // import './i18n';

// // const NavBar = lazy(() => import('./NavBar'));
// // const TabBar = lazy(() => import('./TabBar'));
// // const Home = lazy(() => import('./Home'));
// // const Detail = lazy(() => import('./Detail'));
// // const Upload = lazy(() => import('./Upload'));
// // const Login = lazy(() => import('./Login'));
// // const Join = lazy(() => import('./Join'));
// // const HeartList = lazy(() => import('./HeartList'));
// // const ChatList = lazy(() => import('./ChatList'));
// // const ChatView = lazy(() => import('./ChatView'));
// // const Chat = lazy(() => import('./Chat'));
// // const Edit = lazy(() => import('./Edit'));
// // const Settings = lazy(() => import('./Settings'));
// // const MyTown = lazy(() => import('./MyTown'));
// // const ImageViewer = lazy(()=>import('./ImageViewer'));
// // const NotFound = lazy(() => import('./NotFound.js'));  // Assuming you have a 404 component

// // function useRecaptchaInit({
// //   size = 'invisible',
// //   onSuccess,
// //   onError,
// //   authInstance = auth,
// // } = {}) {
// //   useEffect(() => {
// //     let recaptchaVerifier;

// //     try {
// //       recaptchaVerifier = new RecaptchaVerifier(authInstance, 'recaptcha-container', {
// //         'size': size,
// //         'callback': (response) => {
// //           // reCAPTCHA solved
// //           onSuccess && onSuccess(response);
// //         },
// //         'expired-callback': () => {
// //           // reCAPTCHA expired or failed
// //           onError && onError(new Error("reCAPTCHA expired or failed"));
// //         }
// //       }, authInstance);

// //       window.recaptchaVerifier = recaptchaVerifier;
// //     } catch (error) {
// //       console.error("Failed to initialize reCAPTCHA:", error);
// //       onError && onError(error);
// //     }

// //     return () => {
// //       if (window.recaptchaVerifier) {
// //         window.recaptchaVerifier.clear();
// //         window.recaptchaVerifier = null;
// //       }
// //     };
// //   }, [size, onSuccess, onError, authInstance]);
// // }

// function App() {
//   // const theme = useTheme();
//   const [selectedCategory, setSelectedCategory] = React.useState(null);
//   const [selectedBrand, setSelectedBrand] = React.useState(null);
//   const { navbarHeight, tabbarHeight, textInputHeight } = useGlobalContext();
//   const [activeMenu, setActiveMenu] = useState('home');
//   const isMobile = useMediaQuery('(max-width:600px)');
//   const [isMenuVisible, setIsMenuVisible] = useState(false); // 메뉴의 가시성
//   const [menuWidth, setMenuWidth] = useState(200); // 메뉴의 너비
//   const [loading, setLoading] = useState(false);
//   // auth.settings.appVerificationDisabledForTesting = true;

//   // useRecaptchaInit({
//   //   size: 'invisible',
//   //   onSuccess: (response) => {
//   //     console.log("reCAPTCHA solved:", response);
//   //   },
//   //   onError: (error) => {
//   //     console.error("reCAPTCHA error:", error);
//   //   },
//   //   auth,
//   // });
//   useEffect(() => {
//     requestPermission();
//   }, []);
//   useEffect(() => {
//     document.documentElement.style.setProperty(
//       '--navbar-height',
//       `${navbarHeight}px`
//     );
//     document.documentElement.style.setProperty(
//       '--tabbar-height',
//       `${tabbarHeight}px`
//     );
//     document.documentElement.style.setProperty(
//       '--text-input-height',
//       `${textInputHeight}px`
//     );
//   }, [navbarHeight, tabbarHeight, textInputHeight]);

//   const currentUser = useSelector((state) => state.auth.currentUser);

//   if (loading) {
//     return <div>Loading...</div>; // 로딩 중일 때 표시할 내용
//   }

//   const handleSelectCategory = (category) => {
//     setSelectedCategory(category);
//   };

//   const handleSelectBrand = (brand) => {
//     setSelectedBrand(brand);
//   };
//   return (
//     <ThemeProvider theme={theme}>
//       <CssBaseline />
//       <I18nextProvider i18n={i18n}>
//         <Router>
//           {/* <div style={{ overflowX: 'hidden', width: '100%' }}> */}
//           <Box sx={{ overflowX: 'hidden', width: '100%' }}>
//             <Suspense fallback={<div>Loading...</div>}>
//               {!isMobile && (
//                 <>
//                   <NavBar setActiveMenu={setActiveMenu} />
//                   <CategoryBrandSelector
//                     onSelectCategory={handleSelectCategory}
//                     onSelectBrand={handleSelectBrand}
//                   />
//                   <FloatingSidebar />
//                 </>
//               )}
//               {isMobile && <MobileTabBar activeMenu={activeMenu} />}
//               {isMobile && (
//                 <>
//                   <MobileNavBar />
//                   {/* <MobileMenuAppBar />
//           <NotificationBanner /> */}
//                 </>
//               )}

//               {/* <ProductContext.Provider value={{ handleBuyClick, quantity, setQuantity }}> */}
//               {/* <div className="content-wrapper">
//                 {isMenuVisible && (
//                   <div style={{ width: menuWidth }}>
//                     <LeftSideMenu />
//                   </div>
//                 )}
//               <button onClick={() => setIsMenuVisible(!isMenuVisible)}>
//                 {isMenuVisible ? '메뉴 숨기기' : '메뉴 보이기'}
//               </button>
//               <input
//                 type="number"
//                 value={menuWidth}
//                 onChange={(e) => setMenuWidth(Number(e.target.value))}
//               /> */}
//               {/* <div className="main-content">
//                   <div className="landing-page">
//                     <img
//                       src={homeImage}
//                       alt="Site Introduction"
//                       className="landing-image"
//                     />
//                   </div> */}
//               <Box
//                 className="main-content"
//                 sx={{ marginBottom: isMobile ? '56px' : '0' }}
//               >
//                 <ResponsiveContainer>
//                   <CheckPasswordSetup>
//                     <Routes>
//                       {/* General Routes */}

//                       <Route path="/terms-check" component={TermsCheck} />
//                       <Route path="/privacy-check" component={PrivacyCheck} />

//                       <Route path="/" element={<Home />} />
//                       <Route path="/login" element={<Login />} />
//                       <Route path="/join" element={<Join />} />
//                       <Route path="/upload" element={<Upload />} />

//                       {/* Detailed Views */}
//                       <Route path="/detail/:id" element={<Detail />} />
//                       <Route path="/edit/:id" element={<Edit />} />

//                       {/* Chat Related Routes */}
//                       <Route path="/chatlist" element={<ChatList />} />
//                       <Route
//                         path="/chatview/:productId/:chatRoomId"
//                         element={<ChatView />}
//                       />
//                       <Route
//                         path="/storechatview/:storeId/:chatRoomId"
//                         element={<StoreChatView />}
//                       />
//                       <Route path="/chat/:id" element={<Chat />} />
//                       <Route path="/imageviewer" element={<ImageViewer />} />

//                       {/* Other Lists */}
//                       <Route path="/heartlist" element={<HeartList />} />
//                       <Route path="/selllist" element={<SellList />} />
//                       <Route path="/buylist" element={<BuyList />} />
//                       <Route path="/settings" element={<Settings />} />
//                       <Route
//                         path="/settings/keywords"
//                         element={<KeywordMain />}
//                       />
//                       <Route
//                         path="/setpassword"
//                         element={<SignUpSetPassword />}
//                       />
//                       <Route path="/settings/mytown" element={<MyTown />} />
//                       <Route
//                         path="/delete-user-account"
//                         element={<DeleteUserAccount />}
//                       />
//                       <Route
//                         path="/delete-store-account"
//                         element={<DeleteStoreAccount />}
//                       />

//                       {/* KakaoRedirect */}
//                       <Route
//                         path="/auth/kakao/redirect"
//                         element={<KakaoRedirect />}
//                       />

//                       {/* NaverRedirect */}
//                       <Route
//                         path="/auth/naver/redirect"
//                         element={<NaverRedirect />}
//                       />

//                       {/* <Route path="/user_signup" element={<UserSignUpFlow mode={1} />} />
//                 <Route path="/store_signup" element={<StoreSignUpFlow mode={1} />} /> */}
//                       <Route path="/success" element={<SuccessPage />} />
//                       <Route path="/fail" element={<FailPage />} />
//                       <Route
//                         path="/store_dashboard"
//                         element={<StoreDashboard />}
//                       />
//                       <Route path="/storelist" element={<StoreList />} />
//                       <Route path="/mystore/:storeId" element={<MyStore />} />
//                       <Route
//                         path="/store_edit/:storeId"
//                         element={<EditMyStore />}
//                       />
//                       <Route
//                         path="/user_address/:userId"
//                         element={<UserAddress />}
//                       />
//                       <Route
//                         path="/store_home/:storeId"
//                         element={<StoreHome />}
//                       />
//                       <Route
//                         path="/store_product_list/:id"
//                         element={<StoreProductList />}
//                       />
//                       <Route
//                         path="/product_upload"
//                         element={<StoreProductUpload />}
//                       />
//                       <Route
//                         path="/product_detail/:storeId/:productId"
//                         element={<StoreProductDetail />}
//                       />
//                       <Route
//                         path="/edit-promotion/:id"
//                         element={<EditPromotion />}
//                       />
//                       <Route
//                         path="/promotion_list/:id"
//                         element={<PromotionList />}
//                       />
//                       <Route path="/orders/:userId" element={<Orders />} />
//                       <Route path="/returns/:userId" element={<Returns />} />
//                       <Route
//                         path="/order_status/:userId"
//                         element={<OrderStatus />}
//                       />

//                       <Route
//                         path="/store_orders/:storeId"
//                         element={<StoreOrders />}
//                       />
//                       <Route
//                         path="/mypage/:userId/:storeId"
//                         element={<MyPage />}
//                       />
//                       <Route
//                         path="/cartlist/:userId/:storeId"
//                         element={<CartList />}
//                       />
//                       <Route
//                         path="/allcartlist/:userId"
//                         element={<AllCartList />}
//                       />
//                       <Route
//                         path="/editPassword"
//                         element={<SignUpEditPassword />}
//                       />
//                       <Route
//                         path="/phoneNumberVerify"
//                         element={<PhoneNumberVerification />}
//                       />
//                       <Route
//                         path="/terms_agreement"
//                         element={<TermsAgreementScreen />}
//                       />
//                       <Route path="/terms_list" element={<TermsList />} />
//                       <Route
//                         path="/register_terms"
//                         element={<RegisterTerms />}
//                       />

//                       <Route path="/privacy_list" element={<PrivacyList />} />
//                       <Route
//                         path="/privacy_agreement"
//                         element={<PrivacyAgreementScreen />}
//                       />
//                       <Route
//                         path="/register_privacy"
//                         element={<RegisterPrivacy />}
//                       />
//                       <Route
//                         path="/translate"
//                         element={<TranslateComponent />}
//                       />
//                       <Route
//                         path="/user_profile/:userId"
//                         element={<UserProfile />}
//                       />
//                       <Route path="/search" element={<Home />} />
//                       <Route
//                         path="/keyword_search_results"
//                         element={<ProductKeywordSearchResults />}
//                       />
//                       <Route
//                         path="/category_search_results"
//                         element={<ProductCategorySearchResults />}
//                       />
//                       <Route
//                         path="/brand_search_results"
//                         element={<ProductBrandSearchResults />}
//                       />
//                       <Route
//                         path="/reviews/:storeId/:productId/:reviewId"
//                         element={<ProductReviewDetail />}
//                       />
//                       <Route path="/storeProfile" element={<StoreProfile />} />
//                       <Route
//                         path="/storeDeliveryArea"
//                         element={<StoreDeliveryArea />}
//                       />
//                       <Route
//                         path="/storePosition"
//                         element={<StorePosition />}
//                       />
//                       {/* 404 Page */}
//                       <Route path="*" element={<NotFound />} />
//                     </Routes>
//                   </CheckPasswordSetup>
//                 </ResponsiveContainer>
//               </Box>
//               {/* </ProductContext.Provider> */}
//               {/* </div> */}
//               {/* </div> */}
//             </Suspense>
//           </Box>
//         </Router>
//       </I18nextProvider>
//     </ThemeProvider>
//   );
// }
// export default App;

import React, { useRef, useState, useEffect, Suspense } from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { useTheme, CssBaseline } from '@mui/material';
// import { createTheme, ThemeProvider } from '@mui/material/styles';
import { muiTheme, darkTheme, lightTheme } from './theme.ts';
import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
} from '@mui/material/styles';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { GlobalStyles } from './app.styled.ts';
import { deepmerge } from '@mui/utils';
import './css/global.css';
import './App.css';
import homeImage from './assets/images/home.png';
import { AppStore } from './Redux/store.ts';

import ResponsiveContainer from './ResponsiveContainer';
import NavBar from './NavBar/NavBar';
import MobileTabBar from './NavBar/MobileTabBar';
import Home from './Home';
import Detail from './Detail';
import Upload from './Upload';
import Login from './Login';
import Join from './Join';
import Chat from './Chat';
import ChatList from './ChatList';
import ChatView from './ChatView';
import HeartList from './HeartList';
import Edit from './Edit';
import Settings from './Settings';
import MyTown from './SignUp/MyTown';
import ImageViewer from './ImageViewer';
import KakaoRedirect from './KakaoRedirect';
import NaverRedirect from './NaverRedirect';
import NotFound from './NotFound';
import UserSignUpFlow from './SignUp/UserSignUpFlow';
import StoreSignUpFlow from './SignUp/StoreSignUpFlow';
import PhoneNumberVerification from './SignUp/PhoneNumberVerification';
import StoreDashboard from './Stores/StoreAdmin/StoreDashboard';
import StoreList from './Stores/StoreList';
import MyStore from './Stores/MyStore';
import EditMyStore from './Stores/EditMyStore';
import StoreHome from './Stores/StoreHome';
import StoreProductUpload from './Stores/StoreProductUpload';
import StoreProductList from './Stores/StoreProductList';
import StoreProduct from './Stores/StoreProduct';
import StoreProductDetail from './Stores/StoreProductDetail';
import SignUpEditPassword from './SignUp/SignUpEditPassword';
import EditPromotion from './Stores/Promotion/EditPromotion';
import PromotionList from './Stores/Promotion/PromotionList';
import PromotionWithProduct from './Stores/Promotion/PromotionWithProduct';
import CartList from './Stores/Cart/CartList';
import TermsAgreementScreen from './Stores/Admin/TermsAgreementScreen';
import RegisterTerms from './Stores/Admin/RegisterTerms';
import PrivacyList from './Stores/Admin/PrivacyList';
import RegisterPrivacy from './Stores/Admin/RegisterPrivacy';
import UserProfile from './UserProfile';
import MyPage from './Stores/MyPage';
import Orders from './Stores/Orders';
import StoreOrders from './Stores/StoreOrders';
import LeftSideMenu from './LeftSideMenu';
import KeywordMain from './KeywordMain';
import StoreChatView from './Stores/StoreChatView';
import SuccessPage from './Stores/Payment/Toss/SuccessPage';
import FailPage from './Stores/Payment/Toss/FailPage';
import ProductReviewDetail from './Stores/ProductReviewDetail';
import Returns from './Stores/StoreAdmin/Returns';
import OrderStatus from './Stores/OrderStatus';
import ProductKeywordSearchResults from './ProductKeywordSearchResults';
import ProductCategorySearchResults from './ProductCategorySearchResults';
import ProductBrandSearchResults from './ProductBrandSearchResults';
import AllCartList from './Stores/Cart/AllCartList';
import TermsList from './Stores/Admin/TermsList';
import FloatingSidebar from './FloatingSidebar';
import TermsCheck from './Stores/Admin/TermsCheck';
import PrivacyCheck from './Stores/Admin/PrivacyCheck';
import PrivacyAgreementScreen from './Stores/Admin/PrivacyAgreementScreen';
import CategoryBrandSelector from './CategoryBrandSelector';
import SellList from './SellList';
import BuyList from './BuyList';
import StoreDeliveryArea from './SignUp/StoreDeliveryArea';
import StorePosition from './SignUp/StorePosition';
import StoreProfile from './SignUp/StoreProfile';
import UserAddress from './UserAddress';
import TranslateComponent from './TranslateComponent';
import MobileNavBar from './NavBar/MobileNavBar';
import SignUpSetPassword from './SignUp/SignUpSetPassword';
import CheckPasswordSetup from './CheckPasswordSetup';
import DeleteUserAccount from './DeleteUserAccount';
import DeleteStoreAccount from './DeleteStoreAccount';
import ReactGA from 'react-ga';
import NotificationPrompt from './NotificationPrompt';
import UserTransactionPage from './UserTransactionPage';
import MyProfile from './MyProfile';

const TRACKING_ID = 'G-T9BX2YWPYG'; // Google Analytics 추적 ID
ReactGA.initialize(TRACKING_ID);

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.pageview(pathname); // 페이지뷰 추적
  }, [pathname]);

  return null;
};

function AppContent() {
  const location = useLocation();
  const [selectedCategory, setSelectedCategory] = React.useState(null);
  const [selectedBrand, setSelectedBrand] = React.useState(null);
  const [activeMenu, setActiveMenu] = useState('home');
  const isMobile = useMediaQuery('(max-width:600px)');

  const handleSelectCategory = (category) => {
    setSelectedCategory(category);
  };

  const handleSelectBrand = (brand) => {
    setSelectedBrand(brand);
  };

  const darkMode = useSelector((state) => state.app.darkMode);
  const currentTheme = darkMode ? darkTheme : lightTheme;
  return (
    <MuiThemeProvider theme={muiTheme}>
      <StyledThemeProvider theme={currentTheme}>
        {/* <GlobalStyles /> */}
        <CssBaseline />
        <I18nextProvider i18n={i18n}>
          <ScrollToTop />
          <Box sx={{ overflowX: 'hidden', width: '100%' }}>
            <Suspense fallback={<div>Loading...</div>}>
              {!isMobile && (
                <>
                  <NavBar setActiveMenu={setActiveMenu} />
                  <CategoryBrandSelector
                    onSelectCategory={handleSelectCategory}
                    onSelectBrand={handleSelectBrand}
                  />
                  <FloatingSidebar />
                </>
              )}
              {isMobile && <MobileTabBar activeMenu={activeMenu} />}
              {isMobile && (
                <>
                  <MobileNavBar setActiveMenu={setActiveMenu} />
                </>
              )}
              <Box
                className="main-content"
                sx={{ marginBottom: isMobile ? '56px' : '0' }}
              >
                <ResponsiveContainer>
                  <CheckPasswordSetup>
                    <Routes location={location} key={location.pathname}>
                      {/* General Routes */}
                      <Route path="/terms-check" element={<TermsCheck />} />
                      <Route path="/privacy-check" element={<PrivacyCheck />} />
                      <Route path="/" element={<Home />} />
                      <Route path="/login" element={<Login />} />
                      <Route path="/join" element={<Join />} />
                      <Route path="/upload" element={<Upload />} />
                      {/* Detailed Views */}
                      <Route path="/detail/:id" element={<Detail />} />
                      <Route path="/edit/:id" element={<Edit />} />
                      {/* Chat Related Routes */}
                      <Route path="/chatlist" element={<ChatList />} />
                      <Route
                        path="/chatview/:productId/:chatRoomId"
                        element={<ChatView />}
                      />
                      <Route
                        path="/storechatview/:storeId/:chatRoomId"
                        element={<StoreChatView />}
                      />
                      <Route path="/chat/:id" element={<Chat />} />
                      <Route path="/imageviewer" element={<ImageViewer />} />
                      {/* Other Lists */}
                      <Route path="/heartlist" element={<HeartList />} />
                      <Route path="/selllist" element={<SellList />} />
                      <Route path="/buylist" element={<BuyList />} />
                      <Route path="/settings" element={<Settings />} />
                      <Route
                        path="/settings/keywords"
                        element={<KeywordMain />}
                      />
                      <Route
                        path="/setpassword"
                        element={<SignUpSetPassword />}
                      />
                      <Route path="/settings/mytown" element={<MyTown />} />
                      <Route
                        path="/delete-user-account"
                        element={<DeleteUserAccount />}
                      />
                      <Route
                        path="/delete-store-account"
                        element={<DeleteStoreAccount />}
                      />
                      {/* KakaoRedirect */}
                      <Route
                        path="/auth/kakao/redirect"
                        element={<KakaoRedirect />}
                      />
                      {/* NaverRedirect */}
                      <Route
                        path="/auth/naver/redirect"
                        element={<NaverRedirect />}
                      />
                      <Route path="/success" element={<SuccessPage />} />
                      <Route path="/fail" element={<FailPage />} />
                      <Route
                        path="/store_dashboard"
                        element={<StoreDashboard />}
                      />
                      <Route path="/storelist" element={<StoreList />} />
                      <Route path="/mystore/:storeId" element={<MyStore />} />
                      <Route
                        path="/store_edit/:storeId"
                        element={<EditMyStore />}
                      />
                      <Route
                        path="/user_address/:userId"
                        element={<UserAddress />}
                      />
                      <Route
                        path="/store_home/:storeId"
                        element={<StoreHome />}
                      />
                      <Route
                        path="/store_product_list/:id"
                        element={<StoreProductList />}
                      />
                      <Route
                        path="/product_upload"
                        element={<StoreProductUpload />}
                      />
                      <Route
                        path="/product_detail/:storeId/:productId"
                        element={<StoreProductDetail />}
                      />
                      <Route
                        path="/edit-promotion/:id"
                        element={<EditPromotion />}
                      />
                      <Route
                        path="/promotion_list/:id"
                        element={<PromotionList />}
                      />
                      <Route path="/orders/:userId" element={<Orders />} />
                      <Route path="/returns/:userId" element={<Returns />} />
                      <Route
                        path="/order_status/:userId"
                        element={<OrderStatus />}
                      />
                      <Route
                        path="/store_orders/:storeId"
                        element={<StoreOrders />}
                      />
                      <Route
                        path="/mypage/:userId/:storeId"
                        element={<MyPage />}
                      />
                      <Route
                        path="/cartlist/:userId/:storeId"
                        element={<CartList />}
                      />
                      <Route
                        path="/userTransaction/:transactionId"
                        element={<UserTransactionPage />}
                      />
                      <Route
                        path="/allcartlist/:userId"
                        element={<AllCartList />}
                      />
                      <Route
                        path="/editPassword"
                        element={<SignUpEditPassword />}
                      />
                      <Route
                        path="/phoneNumberVerify"
                        element={<PhoneNumberVerification />}
                      />
                      <Route
                        path="/terms_agreement"
                        element={<TermsAgreementScreen />}
                      />
                      <Route path="/terms_list" element={<TermsList />} />
                      <Route
                        path="/register_terms"
                        element={<RegisterTerms />}
                      />
                      <Route path="/privacy_list" element={<PrivacyList />} />
                      <Route
                        path="/privacy_agreement"
                        element={<PrivacyAgreementScreen />}
                      />
                      <Route
                        path="/register_privacy"
                        element={<RegisterPrivacy />}
                      />
                      <Route
                        path="/translate"
                        element={<TranslateComponent />}
                      />
                      <Route
                        path="/my_profile/:userId"
                        element={<MyProfile />}
                      />
                      <Route
                        path="/user_profile/:userId"
                        element={<UserProfile />}
                      />
                      <Route path="/search" element={<Home />} />
                      <Route
                        path="/keyword_search_results"
                        element={<ProductKeywordSearchResults />}
                      />
                      <Route
                        path="/category_search_results"
                        element={<ProductCategorySearchResults />}
                      />
                      <Route
                        path="/brand_search_results"
                        element={<ProductBrandSearchResults />}
                      />
                      <Route
                        path="/reviews/:storeId/:productId/:reviewId"
                        element={<ProductReviewDetail />}
                      />
                      <Route path="/storeProfile" element={<StoreProfile />} />
                      <Route
                        path="/storeDeliveryArea"
                        element={<StoreDeliveryArea />}
                      />
                      <Route
                        path="/storePosition"
                        element={<StorePosition />}
                      />
                      <Route path="*" element={<NotFound />} />
                    </Routes>
                  </CheckPasswordSetup>
                </ResponsiveContainer>
              </Box>
            </Suspense>
          </Box>
        </I18nextProvider>
      </StyledThemeProvider>
    </MuiThemeProvider>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
      {/* <NotificationPrompt /> */}
    </Router>
  );
}

export default App;
